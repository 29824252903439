import React, { useContext, useEffect } from "react";
import Layout from "../components/Layout";
import ContactUs from "../components/ContactUs";
import { GlobalContext } from "../providers/GlobalProvider";

export default function Contact({ location }) {
  const { setPageScrolled, setPageScrollUp } = useContext(GlobalContext);

  useEffect(() => {
    setPageScrolled(false);
    setPageScrollUp(false);
  }, []);

  return (
    <Layout noPrefooter bgColor='#ffffff'>
      <div id="content-scroll">
        <div id="main">
          <ContactUs formType={location.state} />
        </div>
      </div>
    </Layout>
  );
}