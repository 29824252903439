import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { StaticQuery, graphql } from "gatsby";
import { gsap, Power2 } from "gsap";
import { GlobalContext } from "../providers/GlobalProvider";
import Check from "../svgs/Check.svg";
import "../css/contact.css";

export default function ContactUs({ formType }) {
  function formatText(text) {
    /* eslint-disable no-useless-escape */
    text = text.replace(/\\/g, "")
    return text;
  }

  function formatDescription(text) {
    return text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />,
      text])
  }

  const [activeForm, setActiveForm] = useState("");
  const accordion = useRef([]);

  useEffect(() => {
    window.$("dd.accordion-content").slideUp(1).addClass("hide");
  }, []);

  useEffect(() => {
    if (formType) {
      let active = `contact-form-${formType.formType}`;
      setActiveForm(active);
      accordion.current.forEach((ele, i) => {
        if (ele.id === active) {
          slideDown(ele)
        }
      })
    }
  }, [formType]);

  const openAccordion = (index, id) => {
    setFormState({});
    setSuccessMessage("");
    setErrorMessage("");

    if (activeForm === id) {
      setActiveForm("");
      slideUp(accordion.current[index])
    }
    else {
      setActiveForm(id);
      slideDown(accordion.current[index]);
    }
  }

  const slideDown = (element) => {
    window.$(element).next()
      .slideDown(350)
      .parent()
      .siblings("div")
      .children("dd.accordion-content")
      .slideUp(350)
      .prev()
      .removeClass("accordion-active")
  }

  const slideUp = (element) => {
    window.$(element)
      .removeClass("accordion-active")
      .siblings("dd.accordion-content")
      .slideUp(350);
  }


  // FORM SUBMIT 
  const [formState, setFormState] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const handleSubmit = e => {
    setSuccessMessage("");
    setErrorMessage("");

    /* eslint-disable no-useless-escape */
    const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!formState.Email || !emailRegex.test(formState.Email)) {
    setErrorMessage("*Please enter a valid email address.");
  } else {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": e.target.name, ...formState })
    })
      .then(() => setSuccessMessage("Thank you for reaching out! We’ll get back to you as soon as possible."))
      .catch(error => setErrorMessage(error));
  }
    e.preventDefault();
  };

  const handleChange = e => {
    // let name  = (e.target.name).replace(/\\/g, "");
    let name  = (e.target.name);
    setFormState({ ...formState, [name]: e.target.value })
  }

  const { setCursor, setContactCursor, contactCursor } = useContext(GlobalContext);

  const toggleCursor = useCallback(() => {
      setCursor(({ active }) => ({ active: !active }));
  }, []);

  const animateOn = () => {
    setContactCursor(true)
  }

  const animateOut = () => {
    setContactCursor(false)
  }

  const item = useRef();
  const wrap = useRef();
  const box = useRef();

  const handleParallax = (event) => {
    var parallaxContainer = document.querySelector('.parallax-wrap');
    var parallaxItem = document.querySelector('.parallax-element'),
        b = box.current.getBoundingClientRect(),
        r = parallaxItem.getBoundingClientRect(),
        i = event.pageX - r.left,
        n = event.pageY - r.top,
        d = window.pageYOffset||document.documentElement.scrollTop,
        speed = 3,
        posx = r.width - (i * speed) / 100,
        posy = r.height - (n * speed) / 100;

        console.log(b.left, b.top)

    gsap.to('.circle.parallax-wrap', {
      scale: 2,
      ease:Power2.easeOut,
      duration:.3,
      x: '40px',
      y: '90px' 
    })
    gsap.to('.clapat-button.parallax-element', {
      duration:.3,
      x: posx,
      y: posy,
      scale: .5
    })
  } 


  return (
    <div>
      <StaticQuery
        query={graphql`
          query MyContactQuery {
            forms: allContentfulForms(sort: {fields: order}) {
              nodes {
                title
                slug
                order
                headline
                description {
                  description
                }
                formField1
                field1Required
                formField2
                field2Required
                formField3
                field3Required
                formField4
                field4Required
                cta
              }
            }
            contact: contentfulComponent(slug: {eq: "contact-us-page"}) {
              decorator
              description {
                description
              }
              headline
              slug
            }
          }
        `}
        render={(data) => (
          <div
            className="vc_column_container row_padding_bottom flex contact-page-container"
            data-bgcolor="#fff"
          >
            <div className="flex-50">
              <div className="contact-page-left">
                <h3 className="decorator contact-text">{data.contact.decorator}</h3>
                <h1 className="headline dark contact-header mb-35">
                  {data.contact.headline}
                </h1>
                <div className="contact-info-text contact-text subhead dark" key={data.contact.slug}>
                  {formatDescription(data.contact.description.description)}
                </div>
              </div>
            </div>

            <div className="accordion-sec flex-50" id="contact-form-accordion" >
              <dl className="accordion" data-delay="150">
                {data.forms.nodes.map((form, index) => (
                  <div key={form.slug}>
                    <dt id={form.slug} className={`${activeForm === form.slug ? "accordion-active" : ""}`}
                      onClick={(e) => openAccordion(index, form.slug)}
                      onKeyDown={(e) => openAccordion(index, form.slug)}
                      ref={el => accordion.current[index] = el} 
                      role="presentation"
                      onMouseEnter={toggleCursor}
                      onMouseLeave={toggleCursor}
                      >
                      <span className="link">{form.headline}</span>
                      <div className="acc-button-icon parallax-element">
                        <i className="fa fa-chevron-down"></i>
                      </div>
                    </dt>

                    <dd className={`accordion-content`} role="presentation">

                      {form.description ? form.description.description : ""}
                      <br />
                      <div className="contact-formular">
                        <form
                          name={form.title}
                          method="POST"
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                          id={form.slug + '-submit'}
                          onSubmit={handleSubmit}
                          role="presentation"
                        >
                          <input type="hidden" name="form-name" value={form.title} />
                          <div
                            className="input_padding_bottom  mt-36"
                            data-delay="100"
                          >
                            <input
                              name={form.formField1}
                              type="text"
                              id={form.formField1}
                              size="30"
                              placeholder={form.field1Required ? formatText(form.formField1 + "*") : formatText(form.formField1)}
                              required={form.field1Required ? form.field1Required : ''}
                              onChange={handleChange}
                              value={formState.formField1}
                            />
                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                            <label
                              className="input_label"
                              htmlFor={form.formField1}
                            ></label>
                          </div>

                          <div
                            className="input_padding_bottom "
                            data-delay="150"
                          >
                            <input
                              name={form.formField2}
                              type="text"
                              id={form.formField2}
                              size="30"
                              placeholder={form.field2Required ? formatText(form.formField2 + "*") : formatText(form.formField2)}
                              required={form.field2Required ? form.field2Required : ''}
                              onChange={handleChange}
                              value={formState.formField2}
                            />
                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                            <label
                              className="input_label"
                              htmlFor={form.formField2}
                            ></label>
                          </div>
                          <div
                            className="input_padding_bottom "
                            data-delay="150"
                          >
                            <input
                              name={form.formField3}
                              type="text"
                              id={form.formField3}
                              size="30"
                              placeholder={form.field3Required ? formatText(form.formField3 + "*") : formatText(form.formField3)}
                              required={form.field3Required ? form.field3Required : ''}
                              onChange={handleChange}
                              value={formState.formField3}
                            />
                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                            <label
                              className="input_label"
                              htmlFor={form.formField3}
                            ></label>
                          </div>
                          <div className="form-textarea" data-delay="100">
                            <textarea
                              name={form.formField4}
                              cols="40"
                              rows="4"
                              id={form.formField4}
                              placeholder={form.field4Required ? formatText(form.formField4 + "*") : formatText(form.formField4)}
                              required={form.field4Required ? form.field4Required : ''}
                              onChange={handleChange}
                              value={formState.formField4}
                            ></textarea>
                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                            <label
                              className="input_label slow"
                              htmlFor={form.formField4}
                            ></label>
                          </div>
                          <div className="formvalidate" id="contactform">
                            <div className={`success-message ${successMessage !== "" ? "show" : "none"}`} >
                              <Check />
                              <span>{successMessage}</span>
                            </div>
                            <div className={`error-message ${errorMessage !== "" ? "show" : "none"}`} >{errorMessage}</div>
                          </div>
                          <div className="button-box " data-delay="100" ref={box}>
                            <div 
                              className="clapat-button-wrap circle parallax-wrap bigger"
                              
                              ref={wrap}
                            >
                              <div 
                                className="clapat-button parallax-element" 
                                ref={item}
                                >
                                <div className="button-border  outline parallax-element-second"
                                  onMouseMove={handleParallax}
                                  onMouseEnter={animateOn}
                                  onMouseLeave={animateOut}>
                                  <button
                                    type="submit"
                                    className="send_message"
                                    id="submit"
                                  >
                                    {form.cta}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        )}
      />
    </div>
  );
}
